<template>
  <b-container>
    <b-modal id="add-address" size="lg" centered hide-header hide-footer>
      <div
        class="d-flex justify-content-between align-content-center border-bottom"
      >
        <h3 class="text-primary mb-2">{{ $t("payment.addNewAddress") }}</h3>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form
          class="position-relative form-top p-4"
          @submit.prevent="handleSubmit(addAddress)"
        >
          <b-row class="mt-4">
            <b-col md="6">
              <input-form
                class="mb-3"
                :validate="'required'"
                :name="$t('payment.clientName')"
                :label="$t('payment.clientName')"
                :placeholder="$t('payment.clientName')"
                v-model="location.name"
              />
              <main-select
                :reduce="(category) => category.id"
                :options="allCountries"
                v-model="location.country"
                :validate="'required'"
                :labelTitle="$t('payment.country')"
                label="name"
                :placeholder="$t('payment.country')"
                dir="rtl"
              />
              <input-form
                class="mb-3"
                :name="$t('payment.street')"
                :validate="'required'"
                :label="$t('payment.street')"
                :placeholder="$t('payment.street')"
                v-model="location.street"
              />
            </b-col>
            <b-col md="6">
              <input-form
                class="mb-3"
                :name="$t('payment.phoneNumber')"
                :validate="'required|numeric|min:9|max:11'"
                :label="$t('payment.phoneNumber')"
                :placeholder="$t('payment.phoneNumber')"
                v-model="location.phone"
              />
              <!--          <input-form-->
              <!--              class="mb-3"-->
              <!--              name="id"-->
              <!--              :label="$t('payment.city')"-->
              <!--              :placeholder="$t('payment.city')"-->
              <!--              v-model="location.city"-->
              <!--          />-->
              <main-select
                :reduce="(city) => city.id"
                :options="allCities"
                v-model="location.city"
                :labelTitle="$t('payment.city')"
                :placeholder="$t('payment.city')"
                label="name"
                dir="rtl"
              />
              <input-form
                class="mb-3"
                :name="$t('payment.district')"
                :validate="'required'"
                :label="$t('payment.district')"
                :placeholder="$t('payment.district')"
                v-model="location.district"
              />
            </b-col>
            <b-col md="12">
              <input-form
                class="mb-3"
                :validate="'required'"
                :name="$t('payment.addressDetails')"
                :label="$t('payment.addressDetails')"
                :placeholder="$t('payment.specialSign')"
                v-model="location.specialSign"
              />
            </b-col>
          </b-row>
          <div
            class="d-flex justify-content-center align-items-center w-35 m-auto mt-3"
          >
            <b-button type="submit" variant="primary" class="px-2 py-2" block
              ><span class="font-size-16">{{
                !selectedAddress ? $t("payment.addAddress") : "تعديل العنوان"
              }}</span></b-button
            >
          </div>
        </b-form>
      </ValidationObserver>
    </b-modal>
    <b-modal id="add-new-card" size="lg" centered hide-header hide-footer>
      <addNewPaymentModel />
    </b-modal>
    <b-row class="mt-5 payment-actions-row flex-row-reverse">
      <b-col lg="4" class="mb-4">
        <iq-card class="discount-code-card py-3 px-3">
          <h3 class="text-primary mb-2">{{ $t("payment.discountCode") }}</h3>
          <div class="d-flex align-items-center">
            <b-input
              class="m-0 w-75"
              name="id"
              :placeholder="$t('payment.discountCode')"
              v-model="discountCode"
            >
            </b-input>
            <b-button
              @click="addCoupon()"
              v-if="!discountValue"
              variant="primary"
              class="text-white py-2 m-0 border-0"
              type="submit"
              :disabled="!disableBtn"
            >
              <span class="min-text px-2 font-size-16">
                {{ $t("payment.activate") }}
              </span>
            </b-button>
            <b-button
              @click="
                discountValue = 0;
                discountCode = '';
              "
              v-else
              variant="danger"
              class="text-white py-2 m-0 border-0"
              type="button"
            >
              <span class="min-text px-2 font-size-16">
                {{ $t("payment.diactivate") }}
              </span>
            </b-button>
          </div>
        </iq-card>
        <payment-card
          :discountCode="discountCodeInside"
          :discountValue="discountValue"
          @deleteProductFromReset="deleteProductFromReset"
          :items="getStoreDetailsAndProducts()"
        />
      </b-col>
      <b-col lg="8" class="payment-info mb-5">
        <div class="">
          <div class="d-flex justify-content-between align-content-center">
            <h3 class="text-primary mb-2">{{ $t("payment.myAddress") }}</h3>
            <p
              class="text-primary font-size-18 mb-0 cursor-pointer"
              @click="
                selectedAddress = '';
                $bvModal.show('add-address');
              "
            >
              <i class="las la-plus font-weight-bold pl-1"></i
              >{{ $t("payment.addNewAddress") }}
            </p>
          </div>
          <iq-card class="my-2">
            <div
              class="d-flex align-items-center justify-content-between mr-4 ml-4 my-0 options-border"
              v-for="locate in locations"
              :key="locate.value"
            >
              <b-form-radio
                class="radio-payment-actions custom-checkbox-color color-danger d-flex align-items-center"
                v-model="selectedAddress"
                :value="locate.id"
                :validate="'required'"
                name="plain-inline"
                @change="onChangeAddress"
                plain
              >
                <div class="d-flex flex-column mr-3 pr-3 py-3">
                  <div
                    class="d-flex align-content-center flex-md-row flex-column"
                  >
                    <h4 class="m-0 mb-2 ml-5 text-primary">
                      {{ locate.name }}
                    </h4>
                    <h4 class="m-0 text-primary">
                      رقم <span class="text-primary">+{{ locate.phone }}</span>
                    </h4>
                  </div>
                  <p class="m-0 text-primary">
                    {{
                      locate.address.length >= 80
                        ? locate.address.slice(0, 80) + "...."
                        : locate.address
                    }}
                  </p>
                </div>
              </b-form-radio>
              <span
                class="edit-old-address"
                @click="
                  getSpacificAddress(locate.id);
                  $bvModal.show('add-address');
                "
                >{{ $t("payment.edit") }}</span
              >
            </div>
          </iq-card>
        </div>

        <div class="">
          <div class="d-flex justify-content-between align-content-center">
            <h3 class="text-primary mb-2">
              {{ $t("payment.paymentOptions") }}
            </h3>
          </div>
          <payment-component
            @payWithCard="addOrder"
            @payWithWallet="addOrder"
            :four-grid="true"
          />
        </div>

        <div class="d-flex justify-content-end gap_1 my-3">
          <!--          <b-button @click="addOrder" variant="primary" class="py-3 px-5 iq-border-radius-5">{{$t('payment.validOrder')}}</b-button>-->
          <router-link :to="{ name: 'stores' }">
            <b-button
              variant="outline-primary"
              class="py-3 px-5 iq-border-radius-5"
              >{{ $t("payment.continueShopping") }}</b-button
            >
          </router-link>
        </div>
        <!--        {{getStoreDetailsAndProducts().store_info.id}}-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";
import PaymentCard from "../../components/cart/paymentCard";
import addNewPaymentModel from "@/modules/servicePages/marketplace/components/cart/paymentModels/addNewPaymentModel";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";
import citiesAndCountriesMixin from "@/mixins/countriesAndCities";
import paymentComponent from "@/components/payment";
import cartFunctions from "@/mixins/cartFunctions";
import paymentMixin from "@/mixins/payWithCard";
import websiteServices from "@/modules/userProfile/services/userProfile";
import Payment from "@/Utils/payment";
export default {
  name: "payment-actions",
  components: { PaymentCard, addNewPaymentModel, paymentComponent },
  mixins: [citiesAndCountriesMixin, cartFunctions, paymentMixin],
  data() {
    return {
      disableBtn: false,
      selectedPayment: "cash",
      discountCode: "",
      discountCodeInside: "",
      location: {
        name: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.name
          : "",
        phone: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.phone
          : "",
        country: "",
        city: "",
        district: "",
        street: "",
        specialSign: "",
      },
      discountValue: 0,
      locations: [],
      payments: [
        {
          text: "الدفع عند الإستلام",
          value: "cash",
          description: "سوف يتم تحصيل مبلغ 12.00 ر.س عند التحصيل النقدي",
        },
        {
          text: "8888 **** **** ****",
          value: "master",
          image: require("@/assets/images/ibbil/payments/Mastercard.png"),
        },
        // {
        //   text: 'الدفع بواسطة مدى',
        //   value: 'mada',
        //   image: require('@/assets/images/ibbil/payments/mada-logo.png')
        // },
      ],
      // locations: {},
      allItems: {},
      selectedAddress: "",
      showPaymentDiv: false,
      selectedPaymentId: null,
      store: {},
      shippingFees: 0
    };
  },
  methods: {
    onChangeAddress() {
      const address = this.locations.find(item => item.id === this.selectedAddress)
      const tax = sessionStorage.getItem("tax")
      const totalWithoutTax = sessionStorage.getItem("totalWithoutTax")
      const orderDetails = sessionStorage.getItem("order_details")
      if (orderDetails) {
        const payload = {
          pickup_name: this.store.name,
          pickup_phone: this.store.phone || '0',
          pickup_address: this.store.address,
          customer_name: address.name,
          customer_phone: address.phone,
          customer_address: address.address,
          customer_email: address.email,
          amount: +totalWithoutTax + +tax - +this.discountValue,
          payment_method: 'visa',
          weight: 1,
          number_of_boxes: JSON.parse(orderDetails).length,
          is_inside_city: true,
          order_details: JSON.parse(orderDetails)
        }
        marketPlace.getDeliveryFees(payload).then(res => {
          if (res.data && res.data.shipping_fees) {
            this.shippingFees = res.data.shipping_fees
            sessionStorage.setItem('shipping_fees', res.data.shipping_fees)
          }
        })
      }
    },
    getStoreInfo() {
      marketPlace.getStoreInfo(this.$route.params.id).then(res => {
        this.store = res.data
      })
    },
    getStoreDetailsAndProducts() {
      return this.$store.getters["cart/getStoreDetailsAndProducts"](
        this.$route.params.id
      );
    },
    getAddress() {
      marketPlace.getAddress().then((res) => {
        this.locations = res.data;
      });
    },
    getSpacificAddress(id) {
      this.selectedAddress = id;
      marketPlace.getSpacificAddress(id).then((res) => {
        const addressComplex = res.data.address.split(",");
        this.location = {
          name: res.data.name,
          phone: res.data.phone,
          country: res.data.country,
          city: res.data.city,
          street: addressComplex[1],
          district: addressComplex[0],
          specialSign: addressComplex[2],
        };
      });
    },
    addAddress() {
      const addressObj = {
        ...this.location,
        address: `${this.location.district}, ${this.location.street}, ${this.location.specialSign}`,
      };
      if (this.selectedAddress) {
        marketPlace
          .editAddress(this.selectedAddress, addressObj)
          .then((res) => {
            core.showSnackbar("success", res.data.message);
            this.$bvModal.hide("add-address");
          })
          .finally(() => {
            this.getAddress();
          });
      } else {
        marketPlace
          .addAddress(addressObj)
          .then((res) => {
            core.showSnackbar("success", res.data.message);
            this.$bvModal.hide("add-address");
          })
          .finally(() => {
            this.getAddress();
            this.onChangeAddress();
          });
      }
    },
    addCoupon() {
      marketPlace
        .addCoupon({
          store_id: this.getStoreDetailsAndProducts().store_info.id,
          coupon_code: this.discountCode,
        })
        .then((res) => {
          core.showSnackbar("success", "تم تفعيل الكود بنجاح");
          this.discountValue = res.data.value;
          this.discountCodeInside = this.discountCode;
        })
        .catch((res) => {
          this.discountValue = 0;
          this.discountCode = "";
          core.showSnackbar("error", res.response.data.message);
        });
    },
    async goToPayment(data) {
      sessionStorage.setItem("initiateData", JSON.stringify(data));
      const returnUrl = window.location.origin + "/payment-status";
      const Pay = new Payment(data.gateway.toLowerCase(), data);
      const url = await Pay.paymentConfig();

      if (data.gateway.toLowerCase() === "hyperpay") {
        this.hyperpayForm = true;

        window.wpwlOptions = {
          onReady: function () {
            console.log("HyperPay widget is ready");
          },
          onComplete: function (result) {
            console.log("Payment completed:", result);
          },
        };

        const script = document.createElement("script");
        script.src = url + data.checkout_id;
        script.onload = await function () {
          console.log("HyperPay script loaded");
        };
        document.body.appendChild(script);

        const form = document.createElement("form");
        form.classList.add("paymentWidgets");
        form.setAttribute("data-brands", "VISA MASTER AMEX");
        form.action = returnUrl;
        document.querySelector(".test-form").appendChild(form);

        this.addEscListener();
      }
    },
    addEscListener() {
      if (!this.escListenerAdded) {
        document.addEventListener("keydown", this.handleEscKey);
        this.escListenerAdded = true;
      }
    },

    handleEscKey(event) {
      if (event.key === "Escape" && this.hyperpayForm) {
        this.hyperpayForm = false;
        document.querySelector(".test-form").innerHTML = "";
        this.enableGoToPayment();
        console.log("HyperPay form hidden and ready to show again");
      }
    },

    disableGoToPayment() {
      this.isPaymentEnabled = false;
      console.log("goToPayment function disabled");
    },

    enableGoToPayment() {
      this.isPaymentEnabled = true;
      console.log("goToPayment function re-enabled");
    },
    clearSessionStorage() {
      sessionStorage.removeItem("order_details")
      sessionStorage.removeItem("discountValue")
      sessionStorage.removeItem("tax")
      sessionStorage.removeItem("totalWithoutTax")
      sessionStorage.removeItem("shipping_fees")
    },
    addOrder(obj) {
      if (this.selectedAddress) {
        this.showPaymentDiv = false;
        marketPlace
          .addOrder({
            address_id: this.selectedAddress,
            store_id: this.$route.params.id,
            payment_method_id: `${obj.id}`,
            PIN_code: obj.PIN_code ? obj.PIN_code : "",
            coupon_code: this.discountCode,
          })
          .then((res) => {
            this.clearSessionStorage()
            if (+obj.id === 1) {
              // this.payWithCard(res.data)
              websiteServices.initiate(res.data).then((res) => {
                this.goToPayment(res.data);
              });
              window.Checkout.showEmbeddedPage("#embed-target1");
              this.$store.commit("cart/deleteAllStoreInCart", {
                store_info: {
                  id: this.$route.params.id,
                },
              });
            } else {
              this.$swal.fire({
                position: "center",
                title: "تم الدفع بنجاح",
                icon: "success",
                timer: 1500,
                // iconHtml: '<img src="https://picsum.photos/100/100">',
                showConfirmButton: false,
              });
              this.showPaymentDiv = true;
              core.showSnackbar("success", res.data.message);
              this.$store.commit("cart/deleteAllStoreInCart", {
                store_info: {
                  id: this.$route.params.id,
                },
              });
              this.$router.push({ name: "user-marketplace" });
            }
          });
      } else {
        core.showSnackbar("error", "يجب أختيار العنوان");
      }
    },
    deleteProductFromReset(product) {
      this.deleteProductInCart(product);
    },
    buyWithCard() {},
  },
  mounted() {
    core.index();
  },
  created() {
    this.getAddress();
    this.getStoreInfo();
  },
  watch: {
    discountCode(value) {
      if (value.length > 0) {
        this.disableBtn = true;
      } else {
        this.disableBtn = false;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.checkIfLogin()) {
        next({ name: "login" });
      } else next();
    });
  },
};
</script>

<style>
.radio-payment-actions {
}
.options-border {
  border-bottom: 1px solid #eeeeee;
}
.options-border:last-child {
  border-bottom: 0;
}
.change-payment-actions {
  border-radius: 0;
  border-bottom: 1px solid red !important;
}
.payment-store-img {
  width: 62px !important;
  height: 62px !important;
  border-radius: 10px;
  overflow: hidden;
}
.payment-store-img img {
  background-size: cover;
  width: 100%;
  height: 100%;
}
/*.payment-info{*/
/*  position: sticky !important;*/
/*  top: 10px;*/
/*  height: fit-content !important*/
/*}*/
.payment-mada-img {
  height: 35px;
  width: 55px;
}
.payment-mada-img img {
  background-size: cover;
  width: 100%;
  height: 100%;
}
/*.payment-visa-img{*/
/*  height: 25px;*/
/*  width: 45px;*/
/*}*/
/*.payment-visa-img img{*/
/*  background-size: cover;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/
input[name*="plain-inline"] {
  accent-color: green;
}
.modal-content {
  background-color: var(--iq-bg-light-color) !important;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
}
.w-35 {
  width: 35% !important;
}
@media (min-width: 768px) {
  .payment-info {
    position: sticky !important;
    top: 10px;
    height: fit-content !important;
  }
  .discount-code-card {
    margin-top: 47px !important;
  }
}
/*@media (min-width: 768px) and (max-width: 991.98px){*/
/*  .payment-info {*/
/*    position: sticky !important;*/
/*    top: 10px;*/
/*    height: fit-content !important;*/
/*  }*/
/*  .discount-code-card{*/
/*    margin-top: 47px !important;*/
/*  }*/
/*  h3{*/
/*    font-size: 22px !important;*/
/*  }*/
/*  .min-text{*/
/*    font-size: 14px !important;*/
/*  }*/
/*}*/
@media (max-width: 575.98px) {
  h6 {
    font-size: 20px !important;
  }
  .min-text {
    font-size: 16px !important;
  }
  h4 {
    font-size: 16px !important;
  }
  p {
    font-size: 13px !important;
  }
}
</style>

<!--<b-form-radio-group-->
<!--    v-model="selected"-->
<!--    :options="options"-->
<!--    name="plain-inline"-->
<!--    plain-->

<!--&gt;</b-form-radio-group>-->
